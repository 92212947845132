import { isEmpty, isNil } from 'ramda';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { useReadMultiGoalCostChartData } from '../../../roboAdvice/adviceSession/proposal/components/costChart/useReadMultiGoalCostChartData';
import { getOnlyModifiedOverrideCostTableData } from './mapping';
import { useCostChartStore } from 'features/roboAdvice/adviceSession/costChart';
import { useQueuedPatchAdviceSession } from 'features/roboAdvice/adviceSession/main/components/useQueuedPatchAdviceSession';
import { useReadCostChartData } from 'features/roboAdvice/adviceSession/proposal/components/costChart/useReadCostChartData';
import {
  useCostForm,
  costForm,
  OVERRIDE_COST_MAX_VALUE,
  OVERRIDE_COST_MIN_VALUE
} from 'features/roboAdvice/adviceSession/proposal/services/costForm';
import { usePageStore as useProposalPageStore } from 'features/roboAdvice/adviceSession/proposal/services/pageStore';
import { useSessionStore } from 'features/roboAdvice/adviceSession/session/services/sessionStore';
import {
  SessionNamespaces,
  SessionPatchTypes
} from 'features/roboAdvice/adviceSession/shared/constants';
import { useGoalsStore } from 'features/roboAdvice/adviceSession/shared/services/goalsStore';
import AdvisorNotes from 'features/shared/components/advisorNotes';
import Button from 'features/shared/components/button/index';
import ErrorMessage from 'features/shared/components/errorMessage';
import { AdviceSessionParams } from 'features/shared/constants/session';
import { Spacing } from 'features/shared/constants/spacing';
import { Typography } from 'features/shared/constants/typography';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  errorMessage: {
    textAlign: 'left'
  },
  overrideCostContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'calc(3rem + 3px)',
    marginTop: Spacing.spacing02
  },
  buttonsContainer: {
    display: 'flex',
    gap: Spacing.spacing02,
    alignSelf: 'flex-end',
    marginTop: Spacing.spacing02
  },
  errorHeader: {
    color: theme.errorNegativeColor,
    marginTop: '0.8rem',
    fontSize: Typography.body2.size,
    lineHeight: Typography.body2.lineHeight
  }
}));

type AdvisorNotesName =
  | 'overrideSummaryAnnualOngoingFeesAdvisorNotes'
  | 'overrideSummaryCustodyFeesAdvisorNotes'
  | 'overrideSummaryOneTimeFeesAdvisorNotes'
  | 'overrideFundOngoingFeesAdvisorNotes'
  | 'overrideFundOneTimeFeesAdvisorNotes';

type ErrorKey = 'overrideCost' | 'overrideFundCosts';

type Props = {
  advisorNotesId: string;
  advisorNotesName: AdvisorNotesName;
  errorKey: ErrorKey;
  isOverrideAdvisorNotesRequired: boolean;
  setIsEditModeActive: React.Dispatch<React.SetStateAction<boolean>>;
};

const OverrideCost = ({
  advisorNotesId,
  advisorNotesName,
  errorKey,
  isOverrideAdvisorNotesRequired,
  setIsEditModeActive
}: Props) => {
  const { adviceSessionId } = useParams<AdviceSessionParams>();
  const classes = useStyles();
  const i18n = useI18n();
  const cultureCode: string = useSelector(
    sessionSelectors.getCurrentUserCultureCode
  );
  const queuedPatchAdviceSession = useQueuedPatchAdviceSession();
  const readCostChartData = useReadCostChartData();
  const readMultiGoalCostChartData = useReadMultiGoalCostChartData();
  const { errors, touched } = useCostForm();
  const { originalTableData } = useCostChartStore();
  const {
    analyticsComponents: { multiGoalCostEnabled }
  } = useCustomerConfig();

  const save = async () => {
    const { values } = costForm.getState();

    if (errors?.[advisorNotesName] || errors?.[errorKey]) {
      costForm.mutators.setFieldTouched(advisorNotesName, true);
      return;
    }

    const { isAdvisoryPageInitialized, isBasicDataInitialized } =
      useSessionStore.getState();

    const { goals } = useGoalsStore.getState();

    const onlyModifiedValues = getOnlyModifiedOverrideCostTableData({
      values,
      originalTableData,
      cultureCode,
      i18n
    });

    costForm.initialize({ ...onlyModifiedValues });
    useProposalPageStore
      .getState()
      .setGoalsToUpdate('readCostChartData', goals);

    await Promise.all([
      queuedPatchAdviceSession({
        id: v4(),
        type: SessionPatchTypes.saveSessionData,
        adviceSessionId,
        namespace: SessionNamespaces.sessionData,
        payload: {
          ...onlyModifiedValues,
          isAdvisoryPageInitialized,
          isBasicDataInitialized
        }
      }),
      multiGoalCostEnabled ? readMultiGoalCostChartData() : readCostChartData()
    ]);

    setIsEditModeActive(false);
  };

  const cancel = () => {
    setIsEditModeActive(false);
    costForm.restart();
  };

  return (
    <>
      {!isNil(errors?.[errorKey]) && !isEmpty(errors?.[errorKey]) && (
        <ErrorMessage className={classes.errorMessage}>
          *{' '}
          {i18n('roboAdvice.proposal.cost.overrideCostValueErrorMessage')
            .replace('{0}', OVERRIDE_COST_MIN_VALUE.toString())
            .replace('{1}', OVERRIDE_COST_MAX_VALUE.toString())}
        </ErrorMessage>
      )}

      <div className={classes.overrideCostContainer}>
        <AdvisorNotes
          header={i18n('roboAdvice.proposal.cost.reasoningForOversteeredCost')}
          id={advisorNotesId}
          name={advisorNotesName}
          required={isOverrideAdvisorNotesRequired}
          subheader={
            isOverrideAdvisorNotesRequired &&
            touched?.[advisorNotesName] &&
            errors?.[advisorNotesName] ? (
              <span className={classes.errorHeader}>
                {i18n(errors?.[advisorNotesName])}
              </span>
            ) : null
          }
        />

        <div className={classes.buttonsContainer}>
          <Button onClick={cancel} buttonType="secondary">
            {i18n('shared.cancel')}
          </Button>

          <Button onClick={save}>{i18n('shared.save')}</Button>
        </div>
      </div>
    </>
  );
};

export default OverrideCost;
